import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Column from "../../components/column"
import Columns from "../../components/columns"
import Constrain from "../../components/constrain"
import HyperCardMini from "../../components/hyper-card-mini"
import Image from "../../components/image"
import NoHyphen from "../../components/no-hyphen"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const LeichteSprache = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheIndexQuery {
      interaktion: file(relativePath: { eq: "sprachen/interaktion-2.jpg" }) {
        ...largeImage
      }
      portrait: file(relativePath: { eq: "sprachen/portrait.jpg" }) {
        ...largeImage
      }
      aussen: file(relativePath: { eq: "sprachen/aussen.jpg" }) {
        ...largeImage
      }
      besuch: file(relativePath: { eq: "leichte-sprache/ihr-besuch.jpg" }) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/leichte-sprache.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Der Hölderlin∙turm in Leichter Sprache"
        description="Der Hölderlin∙turm ist ein Museum. Friedrich Hölderlin hat viele Jahre in dem Turm gewohnt. Deshalb heißt der Turm heute Hölderlin∙turm. Im Museum können Sie viel über Friedrich Hölderlin erfahren. Friedrich Hölderlin war ein Dichter. Er hat viele Gedichte geschrieben. Und er hat Bücher geschrieben."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
            ]}
          />
          <PageTitle>Der Hölderlin∙turm in Leichter Sprache</PageTitle>

          <Columns collapse={[true, false]} space={12} alignY="center">
            <Column>
              <Stack>
                <Paragraph hyphens="no" dropcap={true}>
                  Der Hölderlin∙turm ist ein Museum.
                  <br />
                  Friedrich Hölderlin hat viele Jahre <br />
                  in dem Turm gewohnt.
                  <br />
                  Deshalb heißt der Turm heute Hölderlin∙turm.
                  <br />
                  Im Museum können Sie viel <br />
                  über Friedrich Hölderlin erfahren,
                  <br />
                  Friedrich Hölderlin war ein Dichter.
                  <br />
                  Er hat viele Gedichte geschrieben.
                  <br />
                  Und er hat Bücher geschrieben.
                </Paragraph>
                <AudioPlayer src="leichte-sprache/01-leichte-sprache" />
              </Stack>
            </Column>
            <Column width={["full", "2/5"]}>
              <Image
                image={data.aussen.childImageSharp.gatsbyImageData}
                attribution="David Franck"
                alt="Außenansicht Hölderlinturm"
              />
            </Column>
          </Columns>
        </Stack>

        <Constrain>
          <Stack space={6}>
            <HyperCardMini
              headingElement="h2"
              title={
                <span>
                  Mehr über <NoHyphen>Friedrich</NoHyphen>{" "}
                  <NoHyphen>Hölderlin</NoHyphen>
                </span>
              }
              image={data.portrait}
              to="/leichte-sprache/ueber-hoelderlin"
              cta="Mehr lesen"
            />
            <HyperCardMini
              headingElement="h2"
              title={
                <span>
                  Das kann man im <NoHyphen>Hölderlin∙turm</NoHyphen> machen
                </span>
              }
              image={data.interaktion}
              attribution="David Franck"
              to="/leichte-sprache/machen"
              cta="Mehr lesen"
            />
            <HyperCardMini
              headingElement="h2"
              title={
                <span>
                  Mein Besuch im <NoHyphen>Hölderlin∙turm</NoHyphen>
                </span>
              }
              image={data.besuch}
              attribution="David Franck"
              to="/leichte-sprache/besuch"
              cta="Mehr lesen"
            />
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default LeichteSprache
